import React from "react";

const MenuItem = ({ item }) => {
  return (
    <div className="item">
      <div className="item-title">
        <h4>{item.title}</h4>
        <div className="line"></div>
        <div className="price">{item.price}</div>
      </div>
      <div className="desc">
        <span>{item.description}</span>
      </div>
    </div>
  );
};

export default MenuItem;
