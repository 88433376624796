import React, { useState, useEffect } from "react";
import { images } from "./images";
import "./MobileGallery.css";

const Carousel = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [direction, setDirection] = useState("");

  const nextSlide = () => {
    // Move to the next image, cycling back to 0 if at the end
    setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
    // Adjust thumbnail order such that the next image becomes the first thumbnail
    // and thus becomes the active image in the list div.
    setDirection("next");
  };

  const prevSlide = () => {
    setCurrentIndex((prevIndex) => {
      return prevIndex === 0 ? images.length - 1 : prevIndex - 1;
    });
    setDirection("prev");
  };
  // useEffect hook to reset the direction after a delay
  useEffect(() => {
    // If direction is not empty, start a timeout to clear it
    if (direction !== "") {
      const timer = setTimeout(() => {
        setDirection("");
      }, 500); // Adjust the timeout duration to match your CSS animation duration

      // Clear the timeout if the component unmounts or if direction changes again before the timeout completes
      return () => clearTimeout(timer);
    }
  }, [direction]);

  // Adjusted logic to ensure the first thumbnail is always the active one in the list div
  const getReorderedThumbnails = () => {
    const nextIndex = (currentIndex + 1) % images.length;
    const newImages = [...images];
    // Rotate the array to make the next image the first one
    while (newImages[0] !== images[nextIndex]) {
      newImages.push(newImages.shift());
    }
    return newImages;
  };

  const reorderedImages = getReorderedThumbnails();
  const secondAfterIndex = (currentIndex + 1) % images.length;
  const previousBeforeIndex = (currentIndex - 1) % images.length;

  useEffect(() => {
    images.forEach((image) => {
      const img = new Image();
      img.src = image.src;
    });
  }, []);

  return (
    <div className={`carousel ${direction}`} id='gallery'>
      <div className="list">
        {images.map((image, index) => (
          <div
            key={index}
            className={`item ${
              currentIndex === index ? "active" : ""
            } image-${index} ${secondAfterIndex === index ? "second" : ""} ${
              previousBeforeIndex === index ? "last" : ""
            }`}
          >
            {" "}
            <img src={image.src} alt={image.title} />
            <div className="content">
              <div className="title">{image.title}</div>
              <div className="des" dangerouslySetInnerHTML={{__html: image.desc}}></div>
            </div>
          </div>
        ))}
      </div>
      <div className="thumbnail">
        {reorderedImages.map((image, index) => (
          <div
            key={index}
            className={`item ${currentIndex === index ? "active" : ""} ${
              secondAfterIndex === index ? "second" : ""
            } ${previousBeforeIndex === index ? "last" : ""}`}
          >
            <img src={image.src} alt={image.title} />
            <div className="content">
              <div className="title">{image.title}</div>
            </div>
          </div>
        ))}
      </div>
      <div className="arrows">
        <button id="prev" onClick={prevSlide}>
          {`<`}
        </button>
        <button id="next" onClick={nextSlide}>
          {`>`}
        </button>
      </div>
      <div className="time"></div>
    </div>
  );
};

export default Carousel;
