import React, { useEffect, useLayoutEffect, useState } from "react";
import { BrowserRouter as Router, Routes, Route, useLocation } from "react-router-dom";
import "./App.scss";
import Footer from "./components/footer/Footer";
import Navbar from "./components/navbar/Navbar";
import "./fonts/SaiGonClassique.ttf";
import HomePage from "./components/home";
import OurStoryPage from "./components/ourStory";

const Wrapper = ({ children }) => {
  const location = useLocation();

  useLayoutEffect(() => {
    // Scroll to the top of the page when the route changes
    window.scrollTo({ top: 0, left: 0, behavior: 'instant' });
  }, [location.pathname]);

  return children;
};

function App() {
  // State to track whether the view is mobile
  const [isMobile, setIsMobile] = useState(false);

  // Effect hook to add event listener for resize event
  useEffect(() => {
    const checkIfMobile = () => {
      const match = window.matchMedia("(max-width: 1023px)").matches;
      setIsMobile(match);
    };

    // Check on mount (in case the user starts on mobile)
    checkIfMobile();

    // Add event listener
    window.addEventListener("resize", checkIfMobile);

    // Cleanup event listener on component unmount
    return () => window.removeEventListener("resize", checkIfMobile);
  }, []);

  return (
    <Router>
      <Wrapper>
        <div className="App">
          <Navbar />
          <Routes>
            <Route path="/" element={<HomePage />} />
            <Route path="/our-story" element={<OurStoryPage />} />
          </Routes>
          <Footer />
        </div>
      </Wrapper>
    </Router>

  );
}

export default App;
