import React, { useEffect } from "react";
import "./index.css";
import { useLocation } from "react-router-dom";

const OurStoryPage = () => {
  const ScrollToTop = () => {
    const routeLocation = useLocation(); // Use a different name to avoid the global location conflict

    useEffect(() => {
      window.scrollTo(0, 0); // Scroll to top on route change
    }, [routeLocation]);

    return null; // This component does not render anything visually
  };

  ScrollToTop();

  return (
    <div className="saigon-story pt-[120px]">
      <div className="saigon-story__container px-10 md:px-4 py-6 space-y-8 md:max-w-2xl lg:max-w-4xl xl:max-w-6xl mx-auto text-left overflow-visible">
        <div className="our-story__image-container on-right flex w-full md:w-[45%] relative">
          <img
            src="/tape-2.png"
            alt=""
            className="absolute w-[40%] top-0 translate-y-[-50%] left-[50%] translate-x-[-50%]"
          />
          <img
            src={
              "https://SaiGonPho.b-cdn.net/SaiGonPhoWebsite/OurStory/saigon.jpg"
            }
            alt=""
          />
        </div>
        <h1 className="our-story__title">Saigon, Vietnam</h1>
        <h3>This city means a lot to both of us.</h3>
        <div className="content">
          {`Just the name conjures up images of bustling streets, the scent of street food hanging in the humid air, the vibrant energy of a city that never sleeps. For me, Wayne, it’s more than just a city; it’s home. It’s where my family is, where I spent my formative years before leaving at 19, and a place that’s always held a special place in my heart. But Saigon is also a significant place for Mia; it’s where she pursued her university studies and built her career. For both of us, Saigon represents a unique blend of convenience and abundance. You can find every regional Vietnamese dish imaginable, and even cuisines from around the globe. It’s a city that embraces diversity, a place where you can grab a steaming bowl of phở from your neighbor or a delicious bánh mì from the stall down the street. It’s a city of community, of incredible food, and a place that deeply shaped both of us.`}
        </div>

        <div className="our-story__image-container on-left flex w-full md:w-[45%] relative">
          <img
            src="/tape-2.png"
            alt=""
            className="absolute w-[40%] top-0 translate-y-[-50%] left-[50%] translate-x-[-50%]"
          />
          <img
            src={
              "https://SaiGonPho.b-cdn.net/SaiGonPhoWebsite/OurStory/mia-childhood.jpg"
            }
            alt="mia-childhood"
          />
        </div>

        <h1 className="our-story__title">Mia</h1>
        <h3>From Childhood Ritual to Culinary Passion: A Pho Story</h3>
        <div className="content">
          {`For Mia, the connection to phở runs deeper than the marrow in the bones used to make its broth. As a child in a small rural town in Binh Duong Province, she remembers the daily ritual of her mother preparing phở at their small street stall, the fragrant steam mingling with the bustling energy of the city.  This aroma, so familiar and comforting, was the backdrop to her childhood. But while she appreciated the ritual, her young, picky palate fixated on only one element: the delicate quail egg offered as a topping.  Ignoring the broth, the meat, and the herbs, she would "steal" a tiny treasure from her mother's container of quail eggs, meant for customers, savoring its soft yolk as the rest of the phở went largely untouched.  Her mother, ever watchful, knew what was happening.  But instead of scolding, she smiled, her heart swelling with love for her picky daughter.  She was simply happy that Mia had found something, anything, to eat in those years.  Each morning started the same: the rhythmic thump of the cleaver, the gentle clinking of bowls, the murmur of her mother's voice greeting customers, and the quiet clink of the quail egg being "stolen," a secret symphony of love and sustenance that played out against the backdrop of their bustling phở stall.`}
        </div>
        <div className="flex row items-center gap-10">
          <img
            src={"/quotation-marks.svg"}
            alt={"quotation-marks"}
            className="quotation-marks w-[5%]"
          />
          <div className="quote">
            {`It wasn't until I craved the taste of home that I truly understood the love it held.`}
          </div>
          <img
            src={"/quotation-marks.svg"}
            alt={"quotation-marks"}
            className="quotation-marks w-[5%] rotate-[180deg]"
          />
        </div>
        <div className="content">
          {`When Mia left the familiar comforts of her childhood to attend university in Saigon, her appreciation for phở blossomed.  Away from her mother's watchful eye and the abundance of street food options, she found herself craving the warmth and familiarity of her childhood dish.  It was then, as she sat in a bustling Saigon eatery, a steaming bowl before her, that she finally understood. The hours of simmering, the careful selection of spices, the delicate balance of flavors – it all came into focus. With each spoonful, she tasted not just the ingredients, but the love and dedication her mother poured into every bowl.  It was a revelation that transformed her perspective, turning a childhood comfort into a culinary treasure. And as she savored the rich broth and tender noodles, a newfound love for phở bloomed within her, a love that would eventually lead her to share this beloved dish with her new community in Canada.`}
        </div>

        <div className="our-story__image-container on-right flex w-full md:w-[45%] relative">
          <img
            src="/tape-2.png"
            alt=""
            className="absolute w-[40%] top-0 translate-y-[-50%] left-[50%] translate-x-[-50%]"
          />
          <img
            src={
              "https://SaiGonPho.b-cdn.net/SaiGonPhoWebsite/OurStory/dorset-1.jpg"
            }
            alt="mia-childhood"
          />
        </div>

        <h1 className="our-story__title">Our Journey</h1>
        <h3>
          The dream of opening our own restaurant simmered beneath the suits and
          spreadsheets.
        </h3>
        <div className="content">
          {`Mia and I met in 2018 in Barrie, Ontario. She was studying culinary arts, and we quickly bonded over our shared passion for food. Mia, a talented marketing specialist, and myself, a web developer, had built our careers in the corporate world. But beneath the suits and spreadsheets, the dream of opening our own restaurant simmered. We envisioned a place where we could share our culture and the authentic flavors of Vietnam with our new Canadian community. That dream started to take shape in late 2023, thanks to our incredible friend, Elizabeth Johnson, owner of Pizza On Earth. After a dinner we cooked for her family, she generously offered us the use of her kitchen and coffee shop during the winter months. It was a whirlwind – juggling life in Barrie with sourcing ingredients in Dorset and Toronto, pouring every ounce of energy into making it work. In 2024, we made the leap, putting our white-collar jobs aside to fully dedicate ourselves to Saigon Phở & More.`}
        </div>

        <div className="flex row items-center gap-10">
          <img
            src={"/quotation-marks.svg"}
            alt={"quotation-marks"}
            className="quotation-marks w-[5%]"
          />
          <div className="quote">
            {`When Mia and I met, we knew we shared more than just a love for food; we shared a dream.`}
          </div>
          <img
            src={"/quotation-marks.svg"}
            alt={"quotation-marks"}
            className="quotation-marks w-[5%] rotate-[180deg]"
          />
        </div>

        <div className="our-story__image-container on-left flex w-full md:w-[45%] relative">
          <img
            src="/tape-2.png"
            alt=""
            className="absolute w-[40%] top-0 translate-y-[-50%] left-[50%] translate-x-[-50%]"
          />
          <img
            src={
              "https://SaiGonPho.b-cdn.net/SaiGonPhoWebsite/OurStory/mia-holding-sign.jpg"
            }
            alt="mia-childhood"
          />
        </div>
        <h1 className="our-story__title"><span className="saigonpho-title">Saigon Pho & More</span> Today</h1>
        <h3>Bringing the Heart of Vietnam to Dwight</h3>
        <div className="content">
          {`But it did work. And now, we have our own place: Saigon Phở & More. We wanted to capture the spirit of Saigon – the diversity, the vibrancy, the sheer abundance of delicious food. Like Saigon, we offer more than just phở. You'll find bánh mì, stir-fried noodles, and other Vietnamese favorites on our menu. We believe in quality over quantity, a carefully curated selection of dishes made with the same dedication to flavor that Mia learned from her mother. Every bowl of phở, every bánh mì, every dish we serve is a taste of our home, a taste of our heritage, and a taste of the love we put into everything we do. We’re not just serving food; we’re sharing a piece of ourselves.`}
        </div>
      </div>
    </div>
  );
};

export default OurStoryPage;
