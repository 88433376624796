import React, { Suspense } from "react";
import Hero from "./hero/Hero";
import About from "./about/About";
import Menu from "./menu/Menu";
import MobileGallery from './gallery/MobileGallery'

const HomePage = () => {
  return (
    <div className="homepage">
      <Hero />
      <Suspense fallback={<div>Loading About...</div>}>
        <About />
      </Suspense>
      <Suspense fallback={<div>Loading Menu...</div>}>
        <Menu />
      </Suspense>
      <MobileGallery />
    </div>
  );
};

export default HomePage;
