import React, { useEffect, useState } from "react";
import "./About.scss";

const About = () => {
  const [isFlipped, setIsFlipped] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const scrollY = window.scrollY;
      const flower = document.querySelector(".flower");
      flower.style.backgroundPositionY = `${-scrollY * 0.5}px`;
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const handleFlip = () => {
    setIsFlipped(!isFlipped);
  };

  return (
    <div id="about" className="about">
      <div className="about__container">
        <div className="flower"></div>
        <div className="title">
          <h2>About Us</h2>
        </div>
        <div className="card-wrapper">
          <div className="card first">
            <div className="image"></div>
          </div>
          <div className="card second" onClick={handleFlip}>
            <div
              className={`wrapper flip-card-inner ${
                isFlipped ? "flipped" : ""
              }`}
            >
              <div className="flip-card-front">
                <div className="content">
                  <h3>SaiGon Pho</h3>
                  <p>
                    Discover the heartwarming flavors of Vietnam at Saigon Phở &
                    More, where every steaming bowl carries not just the legacy
                    of tradition but also the warmth of a family's love. At the
                    heart of our community, more than just a restaurant, Saigon
                    Phở & More stands as a vibrant invitation to experience a
                    story woven through time. This story began in a bustling
                    kitchen, one filled with the comforting aromas of simmering
                    broth and fresh herbs, where a young Mia spent her
                    childhood. Amid the clatter of pots and the chatter of
                    diners, Mia learned the art of Phở from the best teacher she
                    could have – her mother.
                  </p>
                  <span style={{marginTop: '15px'}}>Read more</span>
                </div>
              </div>
              <div className="flip-card-back">
                <div className="content">
                  <h3>Mia's Recipe</h3>
                  <p>
                    As Mia grew, so did her passion for cooking. Today, our
                    passionate owner and the heart of this establishment, serves
                    up authentic Vietnamese Phở with recipes lovingly taught by
                    her mother. Each spoonful you savor is a tribute to her
                    heritage, a perfect blend of savory, spice, and everything
                    nice, meticulously crafted with the same devotion and care
                    that Mia experienced growing up. Our Phở is not just food;
                    it embodies a bowl full of cherished memories, a taste of
                    home, lovingly prepared for you to enjoy. Join us at Saigon
                    Pho & More, and you'll feel it too!
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="card third">
            <div className="image"></div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default About;
