import React, { useState, useEffect } from "react";
import { Link as ScrollLink, scroller } from "react-scroll";
import { Link as RouterLink, useLocation } from "react-router-dom";
import "./Navbar.scss";
import MenuOutlinedIcon from "@mui/icons-material/MenuOutlined";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";

const Navbar = () => {
  const [show, setShow] = useState(true);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [lastScrollY, setLastScrollY] = useState(0);
  const location = useLocation();

  // Control navbar visibility
  const controlNavbar = () => {
    if (typeof window !== "undefined") {
      if (!isMenuOpen) {
        setShow(window.scrollY < lastScrollY || window.scrollY < 50);
      }
      setLastScrollY(window.scrollY);
    }
  };

  // Handle scrolling to a section if query exists
  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const targetSection = params.get("scrollTo");
    if (targetSection) {
      scroller.scrollTo(targetSection, {
        duration: 800,
        delay: 0,
        smooth: "easeInOutQuart",
      });
    }
  }, [location]);

  // Add scroll event listener
  useEffect(() => {
    if (typeof window !== "undefined") {
      window.addEventListener("scroll", controlNavbar);
      return () => window.removeEventListener("scroll", controlNavbar);
    }
  }, [lastScrollY, isMenuOpen]);

  return (
    <div className={`navbar ${show ? "visible" : "hidden"}`}>
      <div className="container desktop">
        <RouterLink to="/" className="logo">
          <img
            src="https://saigonpho.b-cdn.net/logo-color.png"
            alt="saigon-pho-logo"
          />
        </RouterLink>
        <ul className="nav-menu">
          <li>
            <RouterLink
              to="/"
              onClick={(e) => {
                e.preventDefault(); // Prevent default navigation
                if (window.location.pathname === "/") {
                  // Remove query parameters
                  const url = new URL(window.location);
                  url.search = ""; // Clear query parameters
                  window.history.replaceState({}, "", url.toString());

                  window.location.reload(); // Reload the page
                  window.scrollTo(0, 0); // Scroll to the top
                }
              }}
              classID="active"
            >
              Home
            </RouterLink>
          </li>
          <li>
            <RouterLink to="/our-story">Our Story</RouterLink>
          </li>
          <li>
            <RouterLink to="/?scrollTo=menu">Our Menu</RouterLink>
          </li>
          <li>
            <RouterLink to="/?scrollTo=gallery">Gallery</RouterLink>
          </li>
          <li>
            <a href="tel:12498771359">Order Takeout</a>
          </li>
        </ul>
      </div>
      <div className="mobile">
        <a href="/">
          <img
            src="https://saigonpho.b-cdn.net/logo-color.png"
            alt="saigon-pho-logo"
          />
        </a>
        <div className="menu-toggle" onClick={() => setIsMenuOpen(!isMenuOpen)}>
          <MenuOutlinedIcon />
        </div>
        <div className={`mobile-wrapper ${isMenuOpen ? "reveal" : ""}`}>
          {isMenuOpen && (
            <>
              <div className="close" onClick={() => setIsMenuOpen(!isMenuOpen)}>
                <CancelOutlinedIcon />
              </div>
              <ul className="menu-nav">
                <li>
                  <RouterLink
                    to="/"
                    onClick={(e) => {
                      e.preventDefault(); // Prevent default navigation
                      if (window.location.pathname === "/") {
                        // Remove query parameters
                        const url = new URL(window.location);
                        url.search = ""; // Clear query parameters
                        window.history.replaceState({}, "", url.toString());

                        window.location.reload(); // Reload the page
                        window.scrollTo(0, 0); // Scroll to the top
                      }
                    }}
                    classID="active"
                  >
                    Home
                  </RouterLink>
                </li>
                <li>
                  <RouterLink to="/our-story" classID="active">
                    <span onClick={() => setIsMenuOpen(!isMenuOpen)}>
                      Our Story
                    </span>
                  </RouterLink>
                </li>
                <li>
                  <RouterLink to="/?scrollTo=menu" classID="active">
                    <span onClick={() => setIsMenuOpen(!isMenuOpen)}>
                      Our Menu
                    </span>
                  </RouterLink>
                </li>
                <li>
                  <ScrollLink to="/?scrollTo=gallery" classID="active">
                    <span onClick={() => setIsMenuOpen(!isMenuOpen)}>
                      Gallery
                    </span>
                  </ScrollLink>
                </li>
                <li>
                  <ScrollLink to="contact" classID="active">
                    <span onClick={() => setIsMenuOpen(!isMenuOpen)}>
                      Contact Us
                    </span>
                  </ScrollLink>
                </li>
              </ul>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default Navbar;
