export const menuData = [
    {
      title: "Appetizers",
      items: [
        {
          title: "Crispy Springroll (Pork / Vegetarian - 2pcs)",
          price: "$8",
          description: "A crunchy exterior filled with minced pork, taro, mushrooms, and glass noodles, served with sweet and sour chili dipping sauce.",
        },
        {
          title: "Golden Shrimp Crisp (4pcs)",
          price: "$8",
          description: "Golden, crispy shrimp wonton served with sweet and sour chili dipping sauce.",
        },
        {
          title: "The Daily Pork Wonton Soup",
          price: "$10",
          description: "Flavorful pork wontons in a rich broth garnished with scallions and herbs.",
        },
        {
          title: "Fries",
          price: "$3.5",
          description: "Crispy fries seasoned with your choice of garlic & parmesan or garlic & pepper.",
        },
      ],
    },
    {
      title: "Main Dishes",
      items: [
        {
          title: "Saigon Phở (Beef / Chicken) - Gluten Free",
          price: "$19",
          description: "Fragrant and flavorful noodle soup with rare beef eyeround, brisket, or chicken rice noodles, broth, and fresh herbs.",
        },
        {
          title: "Stirfried Noodle (Beef / Vegetarian / Chicken + $1)",
          price: "$17",
          description: "A combination of tender beef, egg, seasonal vegetable, and noodle in a rich, flavorful sauce.",
        },
        {
            title: "Banh Mi Sandwich (Grilled Pork)",
            price: "$12",
            description: "Crispy outside, soft inside baguette filled with Grilled Pork, homemade butter, pickled vegetable, and fresh herbs.",
        },
        {
          title: "Saigon Crunch Burger",
          price: "$12.5 / $16.5",
          description: "Crispy chicken burger topped with Vietnamese-style slaw and our Saigon Sweet Glaze sauce, served on a soft bun. Upgrade to a combo with fries and a pop.",
        },
      ],
    },
  ];
  