import React from 'react';
import './Menu.scss';
import MenuItem from './MenuItem';
import { menuData } from './MenuData';

const Menu = () => {
  return (
    <div className='menu' id='menu'>
      <div className="container max-w-full">
        <div className="left">
          <div className="image">
            <img src={'https://saigonpho.b-cdn.net/menu-1.JPG'} alt="menu-item-1" />
          </div>
        </div>
        <div className="right">
          <div className="title">
            <h2>Our Menu</h2>
          </div>
          <div className="wrapper">
            {menuData.map((section, index) => (
              <div key={index} className="menu-section">
                <div className="subtitle">
                  <h3>{section.title}</h3>
                </div>
                {section.items.map((item, idx) => (
                  <MenuItem key={idx} item={item} />
                ))}
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Menu;
